import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Content, { HTMLContent } from '../components/Content';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import AccentLine from '../components/AccentLine';
import RecentPosts from '../components/blog/RecentPosts';

const StyledArticle = styled.article`
	.title-section {
		margin-top: 15rem;

		.author {
			color: ${(props) => props.theme.colors.primary};
			font-weight: ${(props) => props.theme.fonts.weights.bold};
			font-size: 2.1rem;
			text-transform: uppercase;
			letter-spacing: 0.09em;
		}

		[class*='AccentLine'] {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}

		h1 {
			font-size: 3rem;
			text-transform: uppercase;
			letter-spacing: 0.12em;
		}

		@media (max-width: 768px) {
			margin-top: 7rem;

			.author {
				font-size: 1.8rem;
			}

			h1 {
				font-size: 2.4rem;
			}
		}
	}

	.body-section {
		padding-top: 5rem;
		padding-bottom: 10rem;

		@media (max-width: 768px) {
			padding-top: 0rem;
			padding-bottom: 5rem;
		}

		.body-content {
			@media (min-width: 769px) {
				column-count: 2;
				column-gap: 5%;
			}
			a {
				color: ${(props) => props.theme.colors.primary};
				text-decoration: underline;
				transition: 300ms;

				&:hover {
					color: ${(props) => props.theme.colors.textGrey};
				}
			}

			h2 {
				color: ${(props) => props.theme.colors.tertiary};
				font-size: 2.1rem;
				margin: 2rem 0;

				@media (max-width: 768px) {
					font-size: 1.8rem;
				}
			}

			p {
				font-size: 1.6rem;
			}

			ul {
				margin: 1rem 0;

				li {
					font-size: 1.6rem;
					font-style: italic;
					font-weight: ${(props) => props.theme.fonts.weights.regular};
					padding-left: 2rem;
					margin-bottom: 1rem;
					position: relative;

					&::before {
						content: '';
						width: 0.7rem;
						height: 0.7rem;
						background-color: ${(props) => props.theme.colors.primary};
						border-radius: 50%;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}

			ol {
				margin: 1rem 0;

				li {
					font-style: italic;
					font-weight: ${(props) => props.theme.fonts.weights.regular};
					margin-left: 2rem;
					margin-bottom: 1rem;
				}
			}

			blockquote {
				padding-left: 2rem;
				border-left: 5px solid ${(props) => props.theme.colors.textGrey};
			}
		}

		.button {
			margin-top: 5rem;

			@media (min-width: 769px) {
				float: right;
				margin-right: 3rem;
			}
		}
	}

	.recent-posts-section {
		background-color: ${(props) => props.theme.colors.lightBgGrey};
		padding-top: 10rem;
		padding-bottom: 10rem;

		h2 {
			font-size: 3.5rem;
			position: relative;
			overflow: hidden;
			margin-bottom: 3rem;

			&::after {
				content: '';
				height: 2px;
				width: 100%;
				background-color: ${(props) => props.theme.colors.quaternary};
				position: absolute;
				top: 50%;
				margin-left: 3rem;
				transform: translateY(-50%);
			}
		}
	}
`;

export const BlogPostTemplate = ({
	title,
	author,
	masthead,
	content,
	contentComponent,
}) => {
	const PostContent = contentComponent || Content;

	return (
		<StyledArticle>
			<section className="section title-section">
				<div className="container">
					<div className="columns is-centered">
						<div className="column has-text-centered">
							<p className="author">By: {author}</p>
							<AccentLine align="center" />
							<h1>{title}</h1>
						</div>
					</div>
				</div>
			</section>
			<section className="section masthead-section">
				<div className="container">
					{masthead && <PreviewCompatibleImage imageInfo={masthead} />}
				</div>
			</section>
			<section className="section body-section">
				<div className="container">
					<div className="columns">
						<div className="column">
							<PostContent className="body-content" content={content} />
							<Link className="button button-2 with-arrow" to="/blog">
								Back to Recent Posts
							</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="section recent-posts-section">
				<div className="container">
					<div className="columns">
						<div className="column">
							<h2>Recent Blog Posts</h2>
						</div>
					</div>
					<RecentPosts />
				</div>
			</section>
		</StyledArticle>
	);
};

BlogPostTemplate.propTypes = {
	title: PropTypes.string,
};

const BlogPost = ({ data }) => {
	const { frontmatter, fields } = data.markdownRemark;

	return (
		<Layout>
			<SEO
				title={frontmatter.title}
				slug={fields.slug}
				image={frontmatter.masthead_image.image}
				description={frontmatter.meta_description}
			/>
			<BlogPostTemplate
				title={frontmatter.title}
				author={frontmatter.author}
				masthead={frontmatter.masthead_image}
				content={data.markdownRemark.html}
				contentComponent={HTMLContent}
			/>
		</Layout>
	);
};

BlogPost.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default BlogPost;

export const blogPostQuery = graphql`
	query BlogPost($id: String!) {
		markdownRemark(id: { eq: $id }) {
			html
			fields {
				slug
			}
			frontmatter {
				title
				author
				meta_description
				masthead_image {
					alt
					image
				}
			}
		}
	}
`;
