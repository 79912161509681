import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import PostDetail from './PostDetail';

const StyledPosts = styled.div`
	background-color: transparent;
`;

const RecentPosts = () => {
	const data = useStaticQuery(graphql`
		query RecentPostsQuery {
			allMarkdownRemark(
				filter: {
					frontmatter: {
						template_key: { eq: "blog-post" }
						is_published: { eq: true }
					}
				}
				sort: { fields: frontmatter___date, order: DESC }
				limit: 4
			) {
				edges {
					node {
						excerpt(pruneLength: 75)
						fields {
							slug
						}
						frontmatter {
							title
							author
							featured_image {
								alt
								image
							}
						}
					}
				}
			}
		}
	`);

	const posts = data.allMarkdownRemark.edges;

	return (
		<StyledPosts className="columns">
			{posts.map(({ node }, i) => (
				<div className="column is-3" key={`${node.frontmatter.title}-${i}`}>
					<PostDetail post={node} />
				</div>
			))}
		</StyledPosts>
	);
};

export default RecentPosts;
